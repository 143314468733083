$(document).ready(function () {
    $('.cancel-create-article').on('click', function (event) {
        event.preventDefault();
        window.location.href = '/';
    });

    $('.cancel-create-user').on('click', function (event) {
        event.preventDefault();
        window.location.href = '/admin/users/all';
    });
});
