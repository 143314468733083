$(document).ready(function () {
    $('.create-image').change(function(){
        previewImageOnCreate(this);
    });

    $('.edit-image').change(function(){
        previewImageOnEdit(this);
    });

    $('.undo-upload').on('click', function() {
        $('.custom-file').css('padding', '30px');
        $('.preview-img').attr('src', '').css('min-height', '0px').css('display', 'none');
        $('.pic-placeholder').show();
        $('.image-caption').show();
        $('.btn-placeholder').show();
        $('.update-image').hide();
        $('.undo-upload').hide();
    })
});

function previewImageOnCreate(input) {
    if (input.files && input.files[0]) {
        let reader = new FileReader();

        reader.onload = function (e) {
            $('.custom-file').css('padding', '10px');
            $('.pic-placeholder').hide();
            $('.image-caption').hide();
            $('.btn-placeholder').hide();
            $('.preview-img').attr('src', e.target.result).css('min-height', '100%').css('object-fit', 'cover').css('display', 'block');
            $('.update-image').show();
            $('.undo-upload').show();
        }

        reader.readAsDataURL(input.files[0]);
    }
}

function previewImageOnEdit(input) {
    if (input.files && input.files[0]) {
        let reader = new FileReader();

        reader.onload = function (e) {
            $('.custom-file').css('padding', '10px');
            $('#previous-image').remove();
            $('.preview-img').attr('src', e.target.result).css('min-height', '100%').css('object-fit', 'cover').css('display', 'block');
        }

        reader.readAsDataURL(input.files[0]);
    }
}
