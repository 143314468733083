$(document).ready(function () {
    $('#news').on('click', function (event) {
        event.preventDefault();
        $('#chevron-left-news').toggle();
        $('#chevron-down-news').toggle();
        $('#news').css('color', 'white');
        $('#news-block').toggleClass('no-news-block');
    });

    $('#users').on('click', function (event) {
        event.preventDefault();
        $('#chevron-left-users').toggle();
        $('#chevron-down-users').toggle();
        $('#users').css('color', 'white');
        $('#users-block').toggleClass('no-users-block');
    });
});
