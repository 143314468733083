$(document).ready(function () {
    $('.send-notification').on('click', function (event) {
        event.preventDefault();
        const articleId = $(this).attr('data-article');
        const shortTitle = $('#short_title').val();
        const shortDescription = $('#short_description').val();
        const token = $('input[type=hidden]').val();

        $.ajax({
            url: `/admin/notifications/${articleId}/store`,
            method: "POST",
            data: {_token: token, short_title: shortTitle, short_description: shortDescription},
        })
            .done(function (response) {
                console.log(response.tokens)
                $('#send-notification').modal('hide');
                $('#success-send-notification').modal('show');
                clearForm();
            })
            .fail(function (response) {
                console.log(response.tokens)
                let errors = response.responseJSON.errors;
                renderErrors(errors);
            })
    });

    $('.hide-modal').on('click', function (event) {
        event.preventDefault();
        $('#success-send-notification').modal('hide');
        window.location.reload();
    });
});

function clearForm() {
    $('#short_title').empty();
    $('#short_description').empty();
}

function renderErrors(errors) {
    let keys = Object.keys(errors);
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let element = $(`#${key}`);
        $(element).addClass('is-invalid');

        let html = `<div class="errors_${key}">`;
        for (let j = 0; j < errors[key].length; j++) {
            html += `<p style="color: #dc3545 !important; margin-bottom: 0">${errors[key][j]}</p>`;
        }

        let modal = $('#send-notification');

        $(element).parent().append(html);
        modal.modal('hide');

        let shortTitle = $('.errors_short_title');
        let shortDescription = $('.errors_short_description');

        if(shortTitle.length > 1) {
            shortTitle.remove();
            $(element).parent().append(html);
            modal.modal('hide');
        }

        if(shortDescription.length > 1) {
            shortDescription.remove();
            $(element).parent().append(html);
            modal.modal('hide');
        }

        $('#short_title').on('keyup', function (event) {
            if ($(this).hasClass('is-invalid')) {
                $('.errors_short_title').remove();
                $(this).removeClass('is-invalid');
            }
        });

        $('#short_description').on('keyup', function (event) {
            if ($(this).hasClass('is-invalid')) {
                $('.errors_short_description').remove();
                $(this).removeClass('is-invalid');
            }
        });
    }
}
