$(document).ready(function () {
    $('.remove-image').on('click', function (event) {
        event.preventDefault();
        const articleId = $(this).attr('data-article-id');
        const token = $('input[type=hidden]').val();

        $.ajax({
            url: `/admin/articles/${articleId}/remove-image`,
            method: "DELETE",
            data: {_token: token}
        })
            .done(function (response) {
                window.location.reload();
            })
    });
});
