function getArticles() {
    $('.search-articles').on('input', function (event) {
        event.preventDefault();
        let type = $(this).attr('data-type');
        let search = $('input[name="search"]').val();
        let url = `/admin/articles/search-articles/${type}?search=${search}`;
        getResponse(url, type);
    });
}

function getResponse(url, type) {
    $.ajax({
        url: url
    }).done(function (response) {
        if (response.count === 0) {
            if (type === 'public') {
                $('.main-content').replaceWith(
                    `<div class="col px-5 py-3 main-content">
                        <div class="d-flex justify-content-between align-items-center px-6" style="position: fixed; top: 50%; left: 50%">
                            <h5>No results found</h5>
                            <a class="ml-3" id="go-back" href="/" style="color: #01A6AB">
                            <h5>Go back</h5>
                            </a>
                        </div>
                </div>`)
            }
            else {
                $('.main-content').replaceWith(
                    `<div class="col px-5 py-3 main-content">
                        <div class="d-flex justify-content-between align-items-center px-6" style="position: fixed; top: 50%; left: 50%">
                            <h5>No results found</h5>
                            <a class="ml-3" id="go-back" href="${type}" style="color: #01A6AB">
                            <h5>Go back</h5>
                            </a>
                        </div>
                </div>`)
            }
        } else if (response.count === 1) {
            $('.count').html(response.count + ' ' + 'Post')
        } else {
            $('.count').html(response.count + ' ' + 'Posts')
        }
        $('.articles').replaceWith(response.view);
    });
}

$(document).ready(function () {
    getArticles();
    $('.articles-block').on('click', '.pagination a', function (event) {
        event.preventDefault();
        let input = $('input[name="search"]');
        let type = input.attr('data-type');
        let search = input.val();
        let page = $(this).attr('href').split('page=')[1];
        let url = `/admin/articles/search-articles/${type}?search=${search}&page=${page}`;
        getResponse(url, type)
    });
});
