window.ClassicEditor = require('ckeditor5-build-classic-plus/build/ckeditor');

ClassicEditor.create(document.querySelector('#body'), {
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: `/admin/articles/upload`,

        // // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: true,

        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            Authorization: 'Bearer <JSON Web Token>'
        },
    }
})
    .then(editor => {
        window.editor = editor;
    })
    .catch(error => {
        window.error = error;
    });

