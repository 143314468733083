$(document).ready(function () {
    $('.delete-user').on('click', function (event) {
        event.preventDefault();
        const userId = $(this).attr('data-user-id');
        const token = $('input[type=hidden]').val();

        $.ajax({
            url: `/admin/users/${userId}/destroy`,
            method: "DELETE",
            data: {_token: token}
        })
            .done(function (response) {
                window.location = document.referrer;
            })
    });

    $('.active-checkbox').on('change', function (event) {
        if (this.checked) {
            $('.active-caption').text('Active')
        } else {
            $('.active-caption').text('Inactive')
        }
    })
});
