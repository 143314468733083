require('./bootstrap');
require('./sidebar');
require('./remove-image');
require('./cancel-create');
require('./delete-user');
require('./save-changes');
require('./send-notification');
require('./preview-image');
require('./ckeditor5');
require('./search-users');
require('./search-articles');
