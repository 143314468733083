function getUsers() {
    $('.search-users').on('input', function (event) {
        event.preventDefault();
        let role = $(this).attr('data-role');
        let search = $('input[name="search"]').val();
        let url = `/admin/users/search-users/${role}?search=${search}`;
        getResponse(url, role);
    });
}

function getResponse(url, role) {
    $.ajax({
        url: url
    }).done(function (response) {
        if (response.count === 0) {
            $('.main-content').replaceWith(
                `<div class="col px-5 py-3 main-content">
                    <div class="d-flex justify-content-between align-items-center px-6" style="position: fixed; top: 50%; left: 50%">
                        <h5>No results found</h5>
                        <a class="ml-3" id="go-back" href="${role}" style="color: #01A6AB">
                            <h5>Go back</h5>
                         </a>
                    </div>
                </div>`)
        } else if (response.count === 1) {
            $('.count').html(response.count + ' ' + 'User')
        } else {
            $('.count').html(response.count + ' ' + 'Users')
        }
        $('.users').replaceWith(response.view);
    });
}

$(document).ready(function () {
    getUsers();
    $('.users-block').on('click', '.pagination a', function (event) {
        event.preventDefault();
        let input = $('input[name="search"]');
        let role = input.attr('data-role');
        let search = input.val();
        let page = $(this).attr('href').split('page=')[1];
        let url = `/admin/users/search-users/${role}?search=${search}&page=${page}`;
        getResponse(url, role)
    });
});

