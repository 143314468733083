$(document).ready(function () {
    let unsaved = false;

    $('.search-users').on('input', function () {
        unsaved = false;
    });

    $('.btn').click(function () {
        unsaved = false;
    });

    $('.input-group-text').click(function () {
        unsaved = false;
    });

    $('.undo-upload').click(function () {
        unsaved = false;
    });

    $('.save-changes').on('keyup change', ':input', function () {
        unsaved = true;
    });

    $('.ck').on('keyup', function () {
        unsaved = true;
    });

    window.addEventListener('beforeunload', function (event) {
        if (unsaved) {
            event.preventDefault();
            event.returnValue = '';
        }
        delete event['returnValue'];
    });
});
